





































.report_page_wrapper {
  .some_details {
    display: flex;
    gap: 1%;
    flex-wrap: wrap;
    padding: 15px;
    .every_part {
      width: calc(96% / 4);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 10px;
      min-height: 150px;
      transition: 0.5s;
      border-radius: 8px;
      &:hover {
        background-color: #eee;
      }
      .title {
        font-weight: bold;
        color: #555;
        font-size: 17px;
      }
    }
  }
}
